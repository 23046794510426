.Intro {
  background-image: url(/img/Intro-Bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 60% center;
  &-Body {
    padding-top: 35px;
    padding-bottom: 75px;
    @include mf(sm) {
      padding-top: 70px;
      padding-bottom: 100px; }
    @include mf(sl) {
      padding-top: 130px;
      padding-bottom: 165px; } }
  &-Heading {
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: 900;
    line-height: 1.3;
    letter-spacing: 0.2px;
    color: #fffbf3;
    text-transform: uppercase;
    @include mf(sm) {
      font-size: 36px; } }
  &-Subheading {
    margin-bottom: 80px;
    font-size: 24px;
    font-weight: 900;
    color: #fff;
    @include mf(sm) {
      margin-bottom: 20px;
      font-size: 30px; }
    br {
      @include mf(sm) {
        display: none; } } }
  &-Form {
    max-width: 300px;
    @include mf(xs) {
      max-width: 260px; } } }
